import React, { useEffect } from "react";

//import css in order
import "../assets/scss/style.scss";
import "normalize.css";
import "../animate.css";
import "bootstrap/dist/css/bootstrap.css";
import "../img/icons/css/ionicons.css";
import "../img/font-awesome/css/font-awesome.css";
import "lightbox2/dist/css/lightbox.min.css";
import "../style.css";
import "../chatbot.css";

//import js libraries
import "jquery/dist/jquery.min.js";
import "popper.js/dist/popper.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import "../libs/easing.js";
import "lightbox2/dist/js/lightbox.min.js";
//import components
import Navbar from "../components/navbar.jsx";
import Intro from "../components/intro.jsx";
//import About from '../components/about.jsx';
//import Portfolio from '../components/portfolio.jsx';
import FeaturesTiles from "../components/sections/FeaturesTiles";
import FeaturesSplit from "../components/sections/FeaturesSplit";
//import Testimonial from '../components/sections/Testimonial';
import Contact from "../components/contact.jsx";
import BackToTop from "../components/back-top.jsx";
import Preloader from "../components/preloader";

const loadScript = (url, id) => {
  const existingScript = document.getElementById(id);
  if (!existingScript) {
    const script = document.createElement("script");
    script.src = url;
    script.id = id;
    document.body.appendChild(script);
  }
};

export default function Home() {
  useEffect(() => {
    // loadScript(
    //   "https://botpress.portacode.com/assets/modules/channel-web/inject.js",
    //   "homeScript"
    // );
    // window.botpressWebChat.init({
    //   host: "https://botpress.portacode.com",
    //   botId: "menas-assistant",
    //   hideWidget: true,
    // });
    return () => {
      const existingScript = document.getElementById("homeScript");
      if (existingScript) {
        existingScript.remove();
      }
    };
  }, []);
  return (
    <React.Fragment>
      <Navbar />
      <Intro />
      {/* <About /> */}
      <FeaturesTiles />
      <FeaturesSplit invertMobile topDivider imageFill />
      {/* <Testimonial topDivider /> */}
      <Contact />
      <BackToTop />
      <Preloader />
    </React.Fragment>
  );
}
