import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = '/'
    }, 5000); // redirects to homepage after 5 seconds

    return () => clearTimeout(timer); // clears timeout on component unmount
  }, [navigate]);

  return <h2>Page not found. You will be redirected to the homepage in 5 seconds...</h2>;
}

export default NotFound;
