import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'My Projects',
    paragraph: 'Below are some of the projects I worked on which can be useful for almost any kind of business'
  };

  return (
    <section
      {...props}
      className={outerClasses}
      style={{
        paddingTop: 50,
        paddingBottom: 100
      }}
      id="work"
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Super Convinient Analytics
                  </div>
                <h3 className="mt-0 mb-12">
                  Data Analytics Dashboards
                  </h3>
                <p className="m-0">
                No more hassle downloading data from multiple different sources and doing lots of manual work with Microsoft Excel just to end up with a huge file that crashes when you try to open it and cannot be shared with anybody since it contains lots of sensitive information. Have one dashboard app installed on your local network that automatically connects to all other apps to generate live reports, summaries and graphs for all important information and KPIs. download printable PDF reports in your prefered format And manage which user can see what.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/analytics.jpg')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Effective Customer Satisfaction
                  </div>
                <h3 className="mt-0 mb-12">
                  Chatbot Agent
                  </h3>
                <p className="m-0">
                  In contrary to a human Agent, A chatbot can easily handle too many customers at the same time, respond immediately and work with any other kind of software to serve the customer.
                  And on the rare occasion that the chatbot is unable to understand or satisfy the customer, it can still transfer them to a human.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/chatbot.jpg')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  High reliability
                  </div>
                <h3 className="mt-0 mb-12"> 
                  Database Replication
                  </h3>
                <p className="m-0">
                  Have no worries about data loss and no need to take backups when you have multiple live replications of all your databases no matter what kind of database it is.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/server.jpg')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>


            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Everything in One Platform
                  </div>
                <h3 className="mt-0 mb-12">
                  Odoo CRM, ERP, manufacturing, warehouse, and much more
                  </h3>
                <p className="m-0">
                  With odoo you can manage everything in your business in one platform, and everything's very customizable and extensible 
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/business.jpg')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;