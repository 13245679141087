import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { isMobile } from "react-device-detect";
import { CSSTransition } from "react-transition-group";
import "./VCard.css";

export default function VCard() {
  const [shouldRender, setShouldRender] = useState(false);
  
  useEffect(() => {
    setShouldRender(true);
    window.location.href = '/XiTdsPo/Menas.vcf';
  }, []);

  return (
    <div className="image-container">
      <p className="caption">
        {isMobile ? "Tap" : "Click"} on the card to download
      </p>
      <a href="/XiTdsPo/Menas.vcf" download>
        <CSSTransition in={shouldRender} timeout={500} classNames="bcard-animate" unmountOnExit>
          <LazyLoadImage
            className="bcard"
            alt="Meena Erian Business Card"
            effect="blur"
            src="/bCard.png"
            placeholder={<div>Loading...</div>}
          />
        </CSSTransition>
      </a>
      <br />
      <br />
      <br />
      <br />
      <a href='/'><p className="caption">{isMobile ? "Tap" : "Click"} here to go to home page</p></a>
    </div>
  );
}
